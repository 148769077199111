import React,{Fragment} from 'react';
import Home from "./page/home/Home";
import 'antd/dist/antd.css';
import "./commom/globalcss.css"

function App() {
  return (
      <Fragment >
        <Home />
      </Fragment>
  );
}

export default App;
