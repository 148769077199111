import axios from "axios/index";

export const queryItems=(callback)=>{
    return async (dispatch) => {
        axios.get("/api/info/items.json").then(function (response) {
            const res = response.data;
            return  callback(res);
        }).catch(function (error) {
            return {}
        });
    }


};
