import React, {Fragment, PureComponent} from 'react'
import { connect } from 'react-redux';
import logo from "../../static/pics/dev_logo.png"
import logojr from "../../static/pics/dev_logo_jr.png"
import {Tabs} from 'antd';
import delpic from "../../static/pics/del.png"
import * as HomeAction from "./Home.action";



import {
    ContainerArea,
    SearchArea,
    SiteArea,
    UtilArea,
    LifeArea,
    LeftMarginArea,
    ContextArea,
    RightMarginArea,
    LogoArea,
    SearchContainer,
    LogoImg,
    SearchSubmit,
    SearchInput,
    FootArea,
    SearchForm, InputContainer, UrlItem, SiteTitle, SiteContent, SiteGroup
} from "./Home.style"
import * as ReactDom from "react-dom";

const { TabPane } = Tabs;


class Home extends PureComponent{
    constructor(props) {
        super(props);
        this.state={
            webs:[],
            tasks:[],
            learn:[],
            jobs:[],
            components:[],
            tools:[],
            test:[],
            moreTools:[],
            design:[],
            process:[],
            ps:[],
            life:[],
            ai:[],
        };

    }


    componentWillMount() {
        this.props.queryItems(res=>this.handleResult(res))
    }

    handleResult(res){
        console.log(res)
        this.setState({
            webs:res["1"],
            tasks:res["2"],
            learn:res["3"],
            jobs:res["4"],
            components:res["5"],
            tools:res["6"],
            test:res["7"],
            moreTools:res["8"],
            design:res["9"],
            process:res["10"],
            ps:res["11"],
            life:res["100"],
            ai:res["12"],
        });
    }




    showDel(e,id){
        if(e.target.value!=""){
            this.refs[id].style.visibility="visible"
        }
    }

    clear(owner,id){
        this.refs[id].value=""
        this.refs[owner].style.visibility="hidden"
    }


    render() {
        var date=new Date()
        var month=date.getMonth()+1
        var day=date.getUTCDate()
        let festDay=false
        if(month==10 && day==24){
            festDay=true
        }

        return (
            <ContainerArea>
                <LeftMarginArea/>
                <ContextArea>
                    <SearchArea>
                        <LogoArea><LogoImg src={festDay?logojr:logo}/></LogoArea>
                        <SearchContainer>
                            <Tabs type="card">
                                <TabPane tab="百度" key="1">
                                    <SearchForm action="https://www.baidu.com/s" method="GET"  searchName={"Baidu"} target="_blank">
                                        <InputContainer> <SearchInput type="text" name="wd" size="38" ref={"bd_input"} onChange={(e)=>this.showDel(e,"del_bd")}/><img  ref="del_bd" onClick={()=>this.clear("del_bd","bd_input")}  src={delpic}  width={25}/> </InputContainer>
                                        <SearchSubmit type="submit" value="Baidu"/>
                                        <input type="hidden" name="ie" value="utf-8" />
                                        <input type="hidden" name="tn" value="ace" />
                                    </SearchForm>



                                </TabPane>
                                <TabPane tab="谷歌" key="2">
                                    <SearchForm action="https://www.google.com/search" role="search"  searchName={"Google"} method="GET" target="_blank">
                                        <InputContainer> <SearchInput type="text" name="q" maxlength="2048" size="38" placeholder={"需连外网"}  ref={"gg_input"} onChange={(e)=>this.showDel(e,"del_gg")}/><img  ref="del_gg" onClick={()=>this.clear("del_gg","gg_input")}  src={delpic}  width={25}/> </InputContainer>
                                        <SearchSubmit type="submit" value="Google" />
                                        <input name="source" type="hidden" value="hp" />
                                        <input value="GOzbYcGwMpq40PEP1-2b6AM" name="ei" type="hidden" />
                                        <input value="ALs-wAMAAAAAYdv6KLeNGd0Oe6AZW6wEvdziprCSwBoy" name="iflsig" type="hidden" />
                                    </SearchForm>
                            </TabPane>
                                <TabPane tab="必应" key="3">
                                    <SearchForm action="https://cn.bing.com/search" method="GET"  searchName={"Bing"} target="_blank">
                                        <InputContainer> <SearchInput type="text" name="q" size="38" ref={"bi_input"} onChange={(e)=>this.showDel(e,"del_bi")}/><img  ref="del_bi" onClick={()=>this.clear("del_bi","bi_input")}  src={delpic}  width={25}/> </InputContainer>
                                        <SearchSubmit type="submit" value="Bing" />
                                        <input type="hidden" name="QBLH" value="form" />
                                    </SearchForm>
                                </TabPane>
                                <TabPane tab="360" key="4">
                                    <SearchForm action="https://www.so.com/s" method="GET"  searchName={"360"} target="_blank">
                                        <InputContainer> <SearchInput type="text" name="q" size="38" ref={"360_input"} onChange={(e)=>this.showDel(e,"del_360")}/><img  ref="del_360" onClick={()=>this.clear("del_360","360_input")}  src={delpic}  width={25}/> </InputContainer>
                                        <SearchSubmit type="submit" value="360" />
                                        <input type="hidden" name="ie" value="utf-8" />
                                        <input type="hidden" name="fr" value="none" />
                                        <input type="hidden" name="src" id="from" value="360sou_newhome" />
                                    </SearchForm>
                                </TabPane>
                                <TabPane tab="搜狗" key="5">
                                    <SearchForm action="https://www.sogou.com/web" name="sf"   searchName={"Sogou"} target="_blank">
                                        <InputContainer> <SearchInput type="text" name="query" size="38" ref={"sg_input"} onChange={(e)=>this.showDel(e,"del_sg")}/><img  ref="del_sg" onClick={()=>this.clear("del_sg","sg_input")}  src={delpic}  width={25}/> </InputContainer>
                                        <SearchSubmit type="submit" value="Sogou" />
                                        <input type="hidden" name="_asf" value="www.sogou.com" />
                                        <input type="hidden" name="_ast" />
                                        <input type="hidden" name="w" value="01019900" />
                                        <input type="hidden" name="p" value="40040100" />
                                        <input type="hidden" name="ie" value="utf8" />
                                        <input type="hidden" name="from" value="index-nologin" />
                                        <input type="hidden" name="s_from" value="index" />
                                    </SearchForm>
                                </TabPane>
                            </Tabs>
                        </SearchContainer>

                    </SearchArea>
                    <SiteArea>
                        <SiteGroup>
                        <SiteTitle>常备</SiteTitle>
                        <SiteContent>    {this.state.webs.map((item)=>
                            <UrlItem href={item.url} target={"_blank"}>{item.name}</UrlItem>)}</SiteContent>
                        </SiteGroup>

                        <SiteGroup>
                            <SiteTitle>刷题</SiteTitle>
                            <SiteContent>    {this.state.tasks.map((item)=>
                                <UrlItem href={item.url} target={"_blank"}>{item.name}</UrlItem>)}</SiteContent>
                        </SiteGroup>

                        <SiteGroup>
                            <SiteTitle>学习</SiteTitle>
                            <SiteContent>    {this.state.learn.map((item)=>
                                <UrlItem href={item.url} target={"_blank"}>{item.name}</UrlItem>)}</SiteContent>
                        </SiteGroup>

                        <SiteGroup>
                            <SiteTitle>接单</SiteTitle>
                            <SiteContent>    {this.state.jobs.map((item)=>
                                <UrlItem href={item.url} target={"_blank"}>{item.name}</UrlItem>)}</SiteContent>
                        </SiteGroup>

                        <SiteGroup>
                            <SiteTitle>组件</SiteTitle>
                            <SiteContent>    {this.state.components.map((item)=>
                                <UrlItem href={item.url} target={"_blank"}>{item.name}</UrlItem>)}</SiteContent>
                        </SiteGroup>

                        <SiteGroup>
                            <SiteTitle>生活</SiteTitle>
                            <SiteContent>    {this.state.life.map((item)=>
                                <UrlItem href={item.url} style={{color:"#34a853"}} target={"_blank"}>{item.name}</UrlItem>)}</SiteContent>
                        </SiteGroup>
                    </SiteArea>


                    <UtilArea>
                        <SiteGroup>
                            <SiteTitle>设计</SiteTitle>
                            <SiteContent>    {this.state.design.map((item)=>
                                <UrlItem href={item.url} target={"_blank"}>{item.name}</UrlItem>)}</SiteContent>
                        </SiteGroup>
                        <SiteGroup>
                            <SiteTitle>流程图</SiteTitle>
                            <SiteContent>    {this.state.process.map((item)=>
                                <UrlItem href={item.url} target={"_blank"}>{item.name}</UrlItem>)}</SiteContent>
                        </SiteGroup>

                        <SiteGroup>
                        <SiteTitle>图片处理</SiteTitle>
                        <SiteContent>    {this.state.ps.map((item)=>
                            <UrlItem href={item.url} target={"_blank"}>{item.name}</UrlItem>)}</SiteContent>
                    </SiteGroup>

                        <SiteGroup>
                            <SiteTitle>AI模型</SiteTitle>
                            <SiteContent>    {this.state.ai.map((item)=>
                                <UrlItem href={item.url} target={"_blank"}>{item.name}</UrlItem>)}</SiteContent>
                        </SiteGroup>

                    </UtilArea>


                    <UtilArea>
                        <SiteGroup>
                            <SiteTitle>常用工具</SiteTitle>
                            <SiteContent>    {this.state.tools.map((item)=>
                                <UrlItem href={item.url} target={"_blank"}>{item.name}</UrlItem>)}</SiteContent>
                        </SiteGroup>
                        <SiteGroup>
                            <SiteTitle>更多工具</SiteTitle>
                            <SiteContent>    {this.state.moreTools.map((item)=>
                                <UrlItem href={item.url} target={"_blank"}>{item.name}</UrlItem>)}</SiteContent>
                        </SiteGroup>

                        <SiteGroup>
                            <SiteTitle>测试</SiteTitle>
                            <SiteContent>    {this.state.test.map((item)=>
                                <UrlItem href={item.url} target={"_blank"}>{item.name}</UrlItem>)}</SiteContent>
                        </SiteGroup>

                    </UtilArea>
                    <FootArea>Copyright © 2024.dev1024.net All rights reserved&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/" target="_blank">鄂ICP备2023015345号-1</a></FootArea>
                </ContextArea>
                <RightMarginArea/>

            </ContainerArea>
        );
    }
};

const mapState=(state)=>({

});

const mapDispatch=(dispatch)=>({
    queryItems(callback){
        dispatch(HomeAction.queryItems(callback))
    }
});


export default connect(mapState,mapDispatch)(Home);