import React,{Fragment} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {GlobalStyle} from "./commom/global";
import {Provider} from 'react-redux'
import store from './store/store';



ReactDOM.render(
    <Provider store={store}>
        <GlobalStyle/>
        <App />
    </Provider>
    , document.getElementById('root'));

